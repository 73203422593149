import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import DonationForm from './pages/DonationForm';
import RichTextEditor from './pages/TextEditor';

const router = createBrowserRouter([
  {
    path: "/",
    element: <div>Main</div>,
  },
  {
    path: "/admin",
    element: <div>Admin
      <div>mensajes:</div>
      <div>crear articulo:</div>
    </div>
  },
  {
    path: "/apoyar",
    element: <div>
      <DonationForm />
    </div>
  },
  {
    path: "/contactar",
    element: <div>contactar</div>
  },
  {
    path: "/about",
    element: <div><RichTextEditor /></div>
  }
]);

function App() {
  return (
    <div className="App">
      <header style={{
        border: '1px solid green', padding: '12px 16px',
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <a href='/'>ISRAEL DEFENSA</a>
        <div style={{
          display: 'flex',
          gap: 12
        }}>
          <a href='/apoyar'>Apoyar</a>
          <a href='/contactar'>Contactar</a>
          <a href='/about'>About</a>
        </div>
      </header>
      <div style={{
        border: '1px solid blue', flex: 1, padding: 16,
        display: 'flex',
        justifyContent: 'center',
      }}>
        <RouterProvider router={router} />
      </div>
      <footer style={{ border: '1px solid black', padding: 16 }}>
        footer
      </footer>
    </div>
  );
}

export default App;
