import React from 'react';
import ReactDOM from 'react-dom';

import Editor from '@monaco-editor/react';

function RichTextEditor() {
  return <Editor height="40vh" width="80vw" defaultLanguage="html" defaultValue="// some comment"
  
  />;
}

export default RichTextEditor