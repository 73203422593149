import React, { useState, useEffect } from 'react';

interface DonationFormProps {}

const DonationForm: React.FC<DonationFormProps> = () => {
  const [amount, setAmount] = useState<number>(5);

  useEffect(() => {
    // Load Donorbox script dynamically
    const script = document.createElement('script');
    script.src = 'https://donorbox.org/widget.js';
    script.async = true;
    script.setAttribute('paypalExpress', 'false');
    document.body.appendChild(script);
  }, []);

  return (
    <div style={{ maxWidth: '500px', margin: '0 auto', padding: '20px', border: '1px solid #ccc', borderRadius: '10px' }}>
      <h3>Donate</h3>
      <iframe
        title="Donorbox Donation Form"
        src="https://donorbox.org/embed/apoyo-4?language=en-us"
        name="donorbox"
        // allowPaymentRequest
        seamless
        frameBorder="0"
        scrolling="no"
        height="900px"
        width="100%"
        style={{ maxWidth: '500px', minWidth: '250px', maxHeight: 'none' }}
        allow="payment"
      />
    </div>
  );
};

export default DonationForm;
